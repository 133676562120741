import React from "react"

import SiteLayout from "../components/SiteLayout/SiteLayout"
import SEO from "../components/SEO/SEO"
import WhoIsItFor from "../components/WhoIsItFor/WhoIsItFor"

const WhoIsItForPage = () => (
  <SiteLayout>
    <SEO title="Who is it for" />
    <WhoIsItFor />
  </SiteLayout>
)

export default WhoIsItForPage
